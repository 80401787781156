import HeaderContainer from "../components/containers/headerContainer";
import github from "../assets/icons/github.svg";
import linkedin from "../assets/icons/icons8-linkedin-80.svg";
import email from "../assets/icons/icons8-mail-80.svg";

const Contact = () => {
    const handleClickScroll = () => {
      const element = document.getElementById("home");
      if (element) {
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({ behavior: "smooth" });
      }
    };
  return (
    <div className="contact">
      <HeaderContainer title="Let's Connect" id="contact" />
      <div className="contact-container">
        <div className="contact-container-left">
          <h3>Get in touch</h3>
          <p>
            You can find the source code for the above projects on my github.
            Use the link below to check it out.
          </p>
          <p>
            Feel free to reach out to me with any questions or comments. I'd
            love to hear from you!
          </p>
          <p>
            <i className="fas fa-phone"></i>{" "}
            <a href="tel:+2348105482210">+234 810 548 2210</a>
          </p>
          <div className="contact-container-left-icons">
            <a
              href="https://www.linkedin.com/in/andrew-lee-0b0b3b1b3/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={linkedin} alt="icon" />
            </a>
            <a
              href="https://github.com/musaddiqaskira"
              target="_blank"
              rel="noreferrer"
            >
              <img src={github} alt="icon" />
            </a>
            <a
              href="mailto:musaddiqaskira@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              <img src={email} alt="icon" />
            </a>
          </div>
          <div className="go-up" on onClick={handleClickScroll}>
                <p>^</p>
          </div>
        </div>
        {/* <div className="contact-container-right">
          <form action="https://formspree.io/f/xnqkzqjw" method="POST">
            <input type="text" name="name" placeholder="Name" required />
            <input type="email" name="email" placeholder="Email" required />
            <textarea name="message" placeholder="Message" required></textarea>
            <button type="submit">Send</button>
          </form>
        </div> */}
      </div>
    </div>
  );
};

export default Contact;
