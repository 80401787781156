import HeaderContainer from "../components/containers/headerContainer";

import ProjectContainer from "../components/containers/ProjectContainer";

import implementation from "../assets/images/implementation.png";
import design from "../assets/images/design.png";
import qrcode from "../assets/images/qrcode.png";

const ProjectOne = () => {
  return (
    <div>
      <HeaderContainer title="My Projects" />
      <ProjectContainer
        image1={design}
        image2={implementation}
        image3={qrcode}
        description="operates as a global gift store. The company started as a small startup business. However, as the market grows, the employees find it hard to track orders, manage customisations and similar problems. GlitteryCart mobile, a solution i built."
        title="GlitteryCart "
        link="Check out the app demo: Download expo go and follow this link or scan the QR Code"
      />
    </div>
  );
};

export default ProjectOne;
