import "./containers.css";
import NavigationText from "../elements/NavigationText";

const NavigationContainer = ({ onClick }) => {
  const textColor = {
    color: "#3F3D56",
  };
  return (
    <div className="navigation-container">
      <NavigationText text="Home" onClick={onClick} id="home" />
      <NavigationText text="About" onClick={onClick} />
      <NavigationText text="Services" onClick={onClick} textColor={textColor} />
      <NavigationText text="Projects" onClick={onClick} textColor={textColor} />
      <NavigationText text="Contact" onClick={onClick} textColor={textColor} />
      <div className="hire-me">
        <NavigationText
          text="Hire Me"
          onClick={onClick}
          textColor={textColor}
          link="mailto:musaddiqaskira@gmail.com"
        />
      </div>
    </div>
  );
};

export default NavigationContainer;
