import ProjectContainer from "../components/containers/ProjectContainer";

import eatwithmemobile from "../assets/images/eatwithmemobile.png";
import eatwithmedesktop from "../assets/images/eatwithmedesktop.png";
import qrcodeeatwithme from "../assets/images/qrcodeeatwithme.png";

const ProjectThree = () => {
  return (
    <div>
      <ProjectContainer
        image1={eatwithmemobile}
        image2={eatwithmedesktop}
        image3={qrcodeeatwithme}
        description="the final website. A full fledged
responsive web application built using the 
cutting edge web framework, React.
It is powered by the largest python web framework
Django. Django was used to  host a dynamic menu section available to customers through a QR Code."
        title="EatWithMe "
        link="Not Just Words: Check out the website"
        url={"https://63b82003cc8caa0078485f86--withmeeat.netlify.app"}
      />
    </div>
  );
};

export default ProjectThree;
