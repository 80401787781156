import { Slide} from "react-awesome-reveal";

const ProjectContainer = ({ image1, image2, image3, description, title, link, direction, url }) => {
  return (
    <div className="project-container" style={{flexDirection: direction}}>
      <Slide direction="down" duration={2000} triggerOnce>
        <div className="project-image-container">
          <img src={image1} alt="project" className="project-image" />
          <img src={image2} alt="project" className="project-image" />
        </div>
      </Slide>
      <Slide cascade="true" direction="right" duration={2000} triggerOnce>
        <div className="project-content-container">
          <p>
            <span className="title">{title}</span>
            {description}
          </p>
          <p className="link">
            <a href={url}>{link}</a>
          </p>
          <img
            src={image3}
            alt=""
            className="QR code"
            style={{ width: "100px", height: "100px" }}
          />
        </div>
      </Slide>
    </div>
  );
};

export default ProjectContainer;
