import { Slide, JackInTheBox, Bounce } from "react-awesome-reveal";

import developer from "../../assets/developer.svg";

import HireMeButton from "../elements/HireMeButton";

const BodyContainer = () => {
  return (
    <div className="body-container">
      <div className="body-container-content">
        <Slide cascade="true" triggerOnce>
          <h3 className="description-1">Hi, my name is</h3>
          <h1>Musaddiq Askira</h1>
          <h3 className="description-2">
            I design and build for the mobile and web.
            <br />
            My experience with the server is no way <br />
            limited.
          </h3>
        </Slide>
        <Bounce delay="200" triggerOnce>
          <HireMeButton
            text="Let's Connect"
            url="mailto:musaddiqaskira@gmail.com"
          />
        </Bounce>
      </div>
      <div className="body-container-image">
        <JackInTheBox delay="300" triggerOnce>
          <img src={developer} alt="developer" />
        </JackInTheBox>
      </div>
    </div>
  );
};

export default BodyContainer;
