import HeaderContainer from "../components/containers/headerContainer";
import AboutContainer from "../components/containers/AboutContainer";

const About = () => {
  return (
    <div className="about-container">
      <div className="about-style-container"></div>
      <HeaderContainer title="About me"/>
      <div className="about-content">
        <AboutContainer/>
      </div>
    </div>
  );
};

export default About;
