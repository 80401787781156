const ServiceContainer = ({icon, title, description}) => {
    return (
        <div className="service-container">
            <div className="service-container-header">
                <img src={icon} alt="icon" />
                <h3>{title}</h3>
            </div>
            <p>{description}</p>
        </div>

    )
}

export default ServiceContainer;