import "./pages.css";
import NavigationContainer from "../components/containers/navigationContainer";
import Logo from "../components/elements/Logo";
import BodyContainer from "../components/containers/BodyContainer";

const Home = () => {
  return (
    <div className="home">
      <div className="home-style-container"></div>
      <div className="home-navigation-container">
        <Logo />
        <NavigationContainer />
      </div>
        <BodyContainer />
    </div>
  );
};

export default Home;
