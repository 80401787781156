import { Bounce, Slide } from "react-awesome-reveal";

import musaddiq from "../../assets/musaddiq.png";
import SkillContainer from "./SkillContainer";
import HireMeButton from "../elements/HireMeButton";

const AboutContainer = () => {
  return (
    <div className="body-container body-alternate-container">
      <div
        className="about-container-image"
        style={{
          
        }}
      >
        <Slide delay="100" triggerOnce>
          <img
            src={musaddiq}
            alt="developer"
          />
        </Slide>
        <Bounce delay="200" triggerOnce>
          <HireMeButton
            text="Chekout my Resume"
            url="https://resume.io/r/jRfV3pECm"
          />
        </Bounce>
      </div>
      <div className="body-container-content body-container-content-alternate">
        <h2 className="description-1" style={{ color: "#FFFFFF" }}>
          The Journey
        </h2>
        <p className="description-2 description-3">
          I started building websites when
          <br />i was 15. Since then, my curiosity kept
          <br />
          growing leading me to learning a lot more
          <br /> technologies.
        </p>
        <h2 className="description-1" style={{ color: "#FFFFFF" }}>
          Skill Set
        </h2>
        <div className="skills-container">
          <SkillContainer skill="UI/UX" />
          <SkillContainer skill="HTML/CSS" />
          <SkillContainer skill="JavaScript" />
          <SkillContainer skill="React JS" />
          <SkillContainer skill="React Native" />
          <SkillContainer skill="Node JS" />
          <SkillContainer skill="MongoDB" />
          <SkillContainer skill="Python" />
          <SkillContainer skill="Django" />
          <SkillContainer skill="Git" />
          <SkillContainer skill="SQL" />
          <SkillContainer skill="MongoDB" />
        </div>
      </div>
    </div>
  );
};

export default AboutContainer;
