
import logo from '../../assets/logo.svg';

const Logo = () => {
    return (
        <div className="logo">
        <img src={logo} alt="logo" />
        </div>
    );
    }

export default Logo;