import SkillText from "../elements/SkillText";

const SkillContainer = ({skill}) => {
  return (
    <div className="skill-container">
      <SkillText skill={skill} />
    </div>
  );
};

export default SkillContainer;
