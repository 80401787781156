

const NavigationText = ({ text, onClick, textColor, link, id }) => {
    return (
      <div className="navigation-text" onClick={onClick} id={id}>
        <a href={link} style={textColor}>{text}</a>
      </div>
    );
    }

export default NavigationText;