import ProjectContainer from "../components/containers/ProjectContainer";

import cookwithmeapp from "../assets/images/cookwithmeapp.png";
import cookwithmeexplore from "../assets/images/cookwithmeexplore.png";

const ProjectFive = () => {
  return (
    <div>
      <ProjectContainer
        image1={cookwithmeapp}
        image2={cookwithmeexplore}
        description="mobile, is a cross-platform mobile
application for the official CookWithMe website.
It includes more features than the regular, with options to search with ingredient and many more.."
        title="CookWithMe "
        link="Coming Soon"
      />
    </div>
  );
};

export default ProjectFive;
