const HireMeButton = ({text, url}) => {
  return (
    <div className="hire-me-button-container">
      <a href={url} className="hire-me-button">
        {text}
      </a>
    </div>
  );
};

export default HireMeButton;
