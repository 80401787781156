import { Slide} from "react-awesome-reveal";

import HeaderContainer from "../components/containers/headerContainer";
import ServiceContainer from "../components/containers/ServiceContainer";
import frontend from "../assets/icons/frontend.svg";
import backend from "../assets/icons/backend.svg";
import mobile from "../assets/icons/mobile.svg";
import ui from "../assets/icons/ui.svg";
import fullstack from "../assets/icons/fullstack.svg";

const Services = () => {
  return (
    <div className="services-container">
      <div className="services-style-container"></div>
      <HeaderContainer title="My Services" />
      <div className="services-container-content">
        <Slide cascade="true" triggerOnce>
        <ServiceContainer
          title="Front-end Web Development"
          description="Responsive web application using  cutting   edge technologies"
          icon={frontend}
        />
        <ServiceContainer
          title="Mobile Application Development"
          description="Cross-platform native mobile application"
          icon={mobile}
        />
        <ServiceContainer
          title="Back-end Development"
          description="Fast and flexible backend application. Modern API Design."
          icon={backend}
        />
        <ServiceContainer
          title="UI/UX Design"
          description="Modern user interface design with fluid interactions."
          icon={ui}
        />
        <ServiceContainer
          title="Full Stack Development"
          description="Full featured mobile and web application with a dedicated backend and DB"
          icon={fullstack}
        />
        </Slide>
      </div>
    </div>
  );
};

export default Services;
