import ProjectContainer from "../components/containers/ProjectContainer";

import cookwithmemobile from "../assets/images/cookwithmemobile.png";
import cookwithmedesktop from "../assets/images/cookwithmedesktop.png";

import qrcodecookwithme from "../assets/images/qrcodecookwithme.png";

const ProjectFour = () => {
  return (
    <div>
      <ProjectContainer
        image1={cookwithmemobile}
        image2={cookwithmedesktop}
        image3={qrcodecookwithme}
        direction="row-reverse"
        description="a startup company i initiated to serve
as a recipe social networking application with support for web and mobile devices. It was built using Django Web framework and plain HTML, CSS and JS."
        title="CookWithMe "
        link="The beta version of the website is live: Check out the website"
        url={"https://cookwithme-production.up.railway.app/recipe/"}
      />
    </div>
  );
};

export default ProjectFour;
