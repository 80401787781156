import HeaderText from "../elements/HeaderText";

const HeaderContainer = ({title, id}) => {
    return (
        <div className="header-container" id={id}>
            <HeaderText>
                {title}
            </HeaderText>
        </div>
    )
}

export default HeaderContainer;
