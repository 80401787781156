

import ProjectContainer from "../components/containers/ProjectContainer";

import eatwithmedesignmobile from "../assets/images/eatwithmedesignmobile.png";
import eatwithmedesign from "../assets/images/eatwithmedesign.png";


const ProjectTwo = () => {
  return (
    <div>
      <ProjectContainer
        image1={eatwithmedesignmobile}
        image2={eatwithmedesign}
        direction="row-reverse"
        description="a restaurant based in Sokoto, Nigeria. A demand for a landing page arises for this restaurant, which i was hired to lead the development.
        This was the proposed design made by me and was approved for development."
        title="EatWithMe "
      />
    </div>
  );
};

export default ProjectTwo;
