import "./App.css";

import { FullPage, Slide } from "react-full-page";

import Home from "./pages/Home";
import About from "./pages/About";
import ProjectOne from "./pages/ProjectOne";
import ProjectTwo from "./pages/ProjectTwo";
import ProjectThree from "./pages/ProjectThree";
import ProjectFour from "./pages/ProjectFour";
import ProjectFive from "./pages/ProjectFive";
import Services from "./pages/Services";
import Contact from "./pages/Contact";

function App() {
  return (
    <div className="App">
      <FullPage controls={false} duration={1000}>
        <Slide>
          <Home />
        </Slide>
        <Slide>
          <About />
        </Slide>
        <Slide>
          <Services />
        </Slide>
        <Slide>
          <ProjectOne />
        </Slide>
        <Slide>
          <ProjectTwo />
        </Slide>
        <Slide>
          <ProjectThree />
        </Slide>
        <Slide>
          <ProjectFour />
        </Slide>
        <Slide>
          <ProjectFive />
        </Slide>
        <Slide>
          <Contact />
        </Slide>
      </FullPage>
    </div>
  );
}

export default App;
